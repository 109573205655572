import { GraphQLQuery, GraphQLVariables, HttpResponse, StrictResponse, delay, graphql } from "msw";

export function getQueryErrorHandler<Query extends GraphQLQuery, Variables extends GraphQLVariables>(
  documentName: string
) {
  return graphql.query<Query, Variables>(documentName, () => {
    return HttpResponse.json({
      data: null,
      errors: [{ message: "An error occured" }],
    });
  });
}

export function getQueryLoadingHandler<Query extends GraphQLQuery, Variables extends GraphQLVariables>(
  documentName: string
) {
  return graphql.query<Query, Variables>(documentName, async () => {
    await delay("infinite");
    return new Response() as StrictResponse<never>;
  });
}

export function getMutationErrorHandler<Mutation extends object, Variables extends GraphQLVariables>(
  documentName: string
) {
  return graphql.mutation<Mutation, Variables>(documentName, () => {
    return HttpResponse.json({
      data: null,
      errors: [{ message: "An error occured" }],
    });
  });
}
