import { useMediaQuery } from "@react-hook/media-query";
import classNames from "classnames";
import { Header, Navbar } from "components";
import {
  ComponentProps,
  FunctionComponent,
  ReactNode,
  Suspense,
  lazy,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import proBackground2Xl from "../../../images/pro-background-2xl.png";
import proBackground3Xl from "../../../images/pro-background-3xl.png";
import proBackgroundXl from "../../../images/pro-background-xl.png";

type PageVariant = "base" | "feature" | "illustrated";

const BottomSheetWrapper = lazy(() => import("../../molecules/BottomSheet/BottomSheetWrapper"));

export type Props = Pick<ComponentProps<typeof Header>, "size" | "subTitle"> &
  Pick<ComponentProps<typeof Navbar>, "title"> & {
    bottom?: ReactNode;
    bottomFloating?: boolean;
    bottomSheet?: ReactNode;
    business?: boolean;
    children?: ReactNode;
    className?: string;
    headerChildren?: ReactNode;
    headerRight?: ReactNode;
    navClassName?: string;
    navLeft?: ComponentProps<typeof Navbar>["left"];
    navRight?: ComponentProps<typeof Navbar>["right"];
    noMain?: boolean;
    onCloseBottomSheet?: () => void;
    rawContent?: boolean;
    roundedBottom?: boolean;
    showNavbar?: boolean;
    showTitleInNavbar?: boolean;
    stretchContentOnLargeScreen?: boolean;
    testId?: string;
    variant?: PageVariant;
    verticalScroll?: boolean;
    withInheritedBackground?: boolean;
  };

const Page: FunctionComponent<Props> = ({
  bottom,
  bottomSheet,
  className = "flex flex-grow flex-col bg-white md:mx-auto md:w-page", // PNF config by default
  children,
  navLeft,
  navRight,
  onCloseBottomSheet,
  variant = "base",
  rawContent = false,
  business = false,
  size,
  roundedBottom = false,
  showNavbar = true,
  showTitleInNavbar = true,
  stretchContentOnLargeScreen = false,
  subTitle,
  title,
  noMain = false,
  navClassName,
  bottomFloating,
  verticalScroll = true,
  testId,
  headerChildren,
  withInheritedBackground,
  headerRight,
}) => {
  const xlScreen = useMediaQuery("only screen and (min-width: 1200px)");
  const doubleXlScreen = useMediaQuery("only screen and (min-width: 1536px)");
  const tripleXlScreen = useMediaQuery("only screen and (min-width: 1920px)");

  const isIllustratedScreen = variant === "illustrated";
  const isFeature = variant === "feature";

  const headerElementRef = useRef<HTMLDivElement | null>(null);
  const lastElementRef = useRef<HTMLDivElement | null>(null);
  const rootElementRef = useRef<HTMLDivElement | null>(null);

  const [showMain, setShowMain] = useState(false);
  const [bottomFloatingOnScroll, setBottomFloatingOnScroll] = useState(false);

  const backgroundImage = useMemo(() => {
    if (!xlScreen || !business || stretchContentOnLargeScreen) return undefined;

    if (tripleXlScreen) {
      return `url(${proBackground3Xl})`;
    }

    if (doubleXlScreen) {
      return `url(${proBackground2Xl})`;
    }

    return `url(${proBackgroundXl})`;
  }, [xlScreen, business, tripleXlScreen, doubleXlScreen, stretchContentOnLargeScreen]);

  useEffect(() => {
    if (noMain) return;

    const headerElement = headerElementRef.current;
    const rootElement = rootElementRef.current;
    const lastElement = lastElementRef.current;

    if (!rootElement || !(headerElement && lastElement)) return;

    const options = {
      root: rootElement,
      rootMargin: "0px",
      threshold: [0],
    };

    const observer = new IntersectionObserver((entries, _observer) => {
      entries.forEach((entry) => {
        if (headerElement && entry.target === headerElement) {
          const newShowMain = !entry.isIntersecting;
          setShowMain(newShowMain);
        } else if (lastElement && entry.target === lastElement) {
          setBottomFloatingOnScroll(!entry.isIntersecting);
        }
      });
    }, options);

    if (headerElement) observer.observe(headerElement);
    if (lastElement) observer.observe(lastElement);

    return () => {
      observer.disconnect();
    };
  }, [noMain]);

  const hasHeader = !!title;

  return (
    <div
      className={
        business ? "absolute inset-0 flex flex-col bg-white xl:bg-contain xl:bg-right xl:bg-no-repeat" : className
      }
      data-testid={testId}
      style={{ backgroundImage }}
    >
      {showNavbar && (
        <Navbar
          className={classNames(
            "z-20 flex-none",
            {
              "absolute top-0": isIllustratedScreen,
              "lg:max-w-desktop": !stretchContentOnLargeScreen,
              "md:px-4-safe": business,
            },
            navClassName
          )}
          floating={showMain && !isIllustratedScreen && !rawContent}
          background={
            business && !isIllustratedScreen
              ? "darkOnMobile"
              : isIllustratedScreen || withInheritedBackground
                ? "none"
                : "light"
          }
          customPadding={business ? "py-1.5-safe md:pt-5.5-safe" : undefined}
          left={navLeft}
          right={navRight}
          showLogo={!business}
          showMain={showMain}
          stretchContentOnLargeScreen={stretchContentOnLargeScreen}
          title={showTitleInNavbar ? title : undefined}
        />
      )}

      <div
        ref={rootElementRef}
        className={classNames("flex flex-1 flex-col", {
          "overflow-y-auto": verticalScroll,
          "overflow-y-hidden": !verticalScroll,
        })}
      >
        <div
          className={classNames("flex-none xl:z-0", {
            "bg-inherit": withInheritedBackground,
            "bg-white": !withInheritedBackground,
            "lg:max-w-desktop": !stretchContentOnLargeScreen,
          })}
          ref={headerElementRef}
        >
          {hasHeader && (
            <Header
              size={isFeature || business ? "large" : size}
              subTitle={subTitle}
              title={title}
              business={true}
              fontWeight="light"
              headerRight={headerRight}
            >
              {headerChildren}
            </Header>
          )}
        </div>

        <div
          className={classNames("relative flex flex-1 flex-col overflow-x-hidden", {
            "bg-inherit": withInheritedBackground,
            "bg-white": !withInheritedBackground,
            "lg:max-w-desktop": !stretchContentOnLargeScreen,
            "md:px-8-safe": !rawContent && business,
            "pb-2": !rawContent && bottom,
            "pb-2-safe": !rawContent && !bottom,
            "px-4-safe pt-2": !rawContent,
          })}
        >
          {children}
        </div>

        <div className="z50 flex-none" ref={lastElementRef} />
      </div>

      {bottom ? (
        <div
          className={classNames("pb-4-safe px-4-safe flex flex-none flex-col bg-white pt-4", {
            "lg:max-w-desktop": !stretchContentOnLargeScreen,
            "rounded-t-xl": roundedBottom,
            "z-10 shadow": isIllustratedScreen || bottomFloating || bottomFloatingOnScroll,
          })}
        >
          <div className={classNames("flex w-full max-w-sm flex-col gap-4 self-center")}>{bottom}</div>
        </div>
      ) : null}
      <Suspense fallback={null}>
        <BottomSheetWrapper sheet={bottomSheet} onClose={onCloseBottomSheet} showBackdrop />
      </Suspense>
    </div>
  );
};

export default Page;
