import { CardScheme } from "@webapps/shared/utils";
import * as Factory from "factory.ts";
import { PaymentMethodPage_PaymentMethodFragment } from "operations";

export const paymentMethodPageFactory = Factory.Sync.makeFactory<PaymentMethodPage_PaymentMethodFragment>({
  __typename: "PaymentMethod",
  cardLast4Digits: "9999",
  cardScheme: CardScheme.VISA,
  id: "card_145smI0DyRoSrKizUaVgrEb0FFInVgta",
  processoutTokenRef: "fake-processout-token-ref",
});
