import { init } from "mixpanel-browser";

export type MixpanelApp = "electra-webapp" | "electra-business" | "electra-partners" | "electra-webviews";

export const initMixpanel = (token: string, app: MixpanelApp, debug: boolean) => {
  if (!token) {
    throw new Error("Missing token for Mixpanel SDK");
  }
  return init(
    token,
    {
      debug,
      // not supported on SPAs
      track_pageview: false,
    },
    app
  );
};

/** EVENTS */
export const BUTTON_CLICKED = {
  ADD_INVITATION_LINE: "add_invitation_line", // params: context
  BULK_REINVITE_USERS: "bulk_reinvite_users", // params: context
  CBE: "CBE", // params: context
  CHARGES_PAGINATION: "charges_pagination", // params: context
  CLOSE_ALERT_MODAL: "close_alert_modal", // params: context
  CLOSE_WELCOME_MODAL: "close_welcome_modal", // params: context
  COPY_REWARD_CODE: "copy_reward_code", // params: context
  CTA_ALERT_MODAL: "cta_alert_modal", // params: context
  CTA_WELCOME_MODAL: "cta_welcome_modal", // params: context, navigation_direction
  DELETE_INVITATION: "delete_invitation", // params: context
  DOWNLOAD_APP: "download_app", // params: context
  DOWNLOAD_APP_FROM_CONNECTORS_SCREEN: "plug_selection_download_app", // params: context
  DOWNLOAD_BULK_INVITATIONS_FILE: "download_bulk_invitations_file", // params: context
  EXPORT_CHARGES: "export_charges", // params: context
  FILTER_DATE: "filter_date", // params: context, filter_name
  FILTER_PAYMENT: "filter_payment", // params: context, filter_name
  HOW_TO_ADD_PROMO_CODE: "how_to_add_promo_code",
  ID_DOCUMENT: "ID", // params: context
  INVITE_USER: "invite_user", // params: context
  INVITE_USERS: "invite_users", // params: invitation_count
  INVOICE_PAGE__DOWNLOAD_APP: "download_app_after_receipt", // params: context
  INVOICE_PAGE__SEND_INVOICE: "send_my_receipt", // params: context
  KBIS: "KBIS",
  MY_CODES: "my_codes", // params: context
  REINVITE_USER: "reinvite_user", // params: invitation_id
  REQUEST_INVITE_USER: "request_invite_user", // params: form_type
  RESET_CHARGES: "reset_charges", // params: context
  SECONDARY_BUTTON_ALERT_MODAL: "secondary_button_alert_modal", // params: context
  SECONDARY_BUTTON_WELCOME_MODAL: "secondary_button_welcome_modal", // params: context
  SEE_EXPLANATION_PAGE: "see_explanation_page", // params: context
  SEE_RECEIPT: "see_receipt", // params: context
  SHARE: "share", // params: context
  SORT_CHARGES: "sort_charges", // params: context, sorting_key
  VIEW_CHARGES: "view_charges", // params: context, filter_name
  VISURA: "VISURA", // params: context
} as const;

export const PAGE_VIEWED = {
  ALERT_MODAL: "alert_modal",
  ANONYMOUS_ACCESS_PAGE__CONFIRMATION_SCREEN: "access_code_sent",
  ANONYMOUS_ACCESS_PAGE__EMAIL_SCREEN: "access_email",
  ANONYMOUS_ACCESS_PAGE__PHONE_SCREEN: "access_phone_number",
  AUTH0_LOGIN_PAGE: "auth0_login",
  CHARGES_PAGE: "charges",
  CHARGE_OBJECTIVE_BOTTOM_SHEET_COMPONENT: "remote_charge_objective",
  CHARGE_PAGE: "charge", // params: charge_id
  CHARGE_POINT_PAGE__CONNECTORS_SCREEN: "plug_selection",
  CHECK_CARD_PAGE: "resume_session",
  DASHBOARD_PAGE: "dashboard",
  EMSP_BADGE_IN_APP_LANDING: "emsp_badge_in_app_landing", // params: entryPoint
  HOME_PAGE: "landing_page",
  INVITATION_CREATION_PAGE: "new_invitation", // params: form_type
  INVITATION_PAGE: "invitation", // params: invitation_id
  PAYMENT_INTENT_AUTHORIZE_BOTTOM_SHEET_COMPONENT: "payment_pre_auth_bottom_sheet",
  PAYMENT_METHOD_PAGE__CREDIT_CARD_NEW_SCREEN: "fill_payment_method",
  PRICING_PAGE: "pricing",
  REFERRAL_EXPLANATIONS: "referral_explanations",
  REFERRAL_PROGRAM: "referral_program", // params: entryPoint
  REFERRAL_REWARDS: "referral_rewards", // params: entryPoint
  REMOTE_CHARGE_PAGE__ACTION_SCREEN: "remote_charge_start_and_plug",
  REMOTE_CHARGE_PAGE__CHARGING_SCREEN: "remote_charge_ongoing",
  REMOTE_CHARGE_PAGE__COMPLETED_SCREEN: "checkout_kit",
  RETRIEVE_CHARGE_SCREEN: "retreive_your_receipt",
  SEND_INVOICE_SCREEN: "charging_receipts_found",
  SETTINGS_PAGE: "settings",
  SIGN_IN_CHALLENGE_CODE_SCREEN: "challenge_code",
  SIGN_IN_PHONE_NUMBER_SCREEN: "phone_number",
  SMB_ONBOARDING_COMPANY: "smb_onboarding__company", // params: funnel_type
  SMB_ONBOARDING_DISPATCH: "smb_onboarding__dispatch", // params: creation_source
  SMB_ONBOARDING_SIGN_UP: "smb_onboarding__sign_up", // params: funnel_type
  SMB_ONBOARDING_STEP_0: "smb_onboarding__step_0", // params: funnel_type
  SMB_ONBOARDING_SUCCESS: "smb_onboarding__success", // params: funnel_type
  SMB_ONBOARDING_VALIDATION: "smb_onboarding__validation", // params: funnel_type
  STATIONS_PAGE: "stations",
  SUPPORT_PAGE: "help",
  USERS_PAGE: "users",
  USER_DELETION_INTENT_PAGE: "user_deletion_intent", // params: user_deletion_intent_id
  USER_INFO_PAGE: "user_info",
  USER_PAGE: "user", // params: acount_user_id
  WELCOME_MODAl: "welcome_modal", // params: acount_user_id
  WRAPPED_PAGE: "electra_retro",
} as const;

/** TYPES */
export type ButtonName = (typeof BUTTON_CLICKED)[keyof typeof BUTTON_CLICKED];
export type Context = "referral_explanations" | "referral_program" | "referral_rewards";
export type CreationSource = "station" | null;
export type EntryPoint = "burger" | "card" | "promo" | "station";
export type FormType = "single" | "multiple";
export type ModalType = "no_invite" | "saved_invites";
export type PageName = (typeof PAGE_VIEWED)[keyof typeof PAGE_VIEWED];
