import AmexIcon from "@webapps/shared/images/icons/card-schemes/amex.svg?react";
import MastercardIcon from "@webapps/shared/images/icons/card-schemes/mastercard.svg?react";
import VisaIcon from "@webapps/shared/images/icons/card-schemes/visa.svg?react";
import CardIcon from "@webapps/shared/images/icons/ri/credit-card.svg?react";

export enum CardScheme {
  AMEX = "American express",
  MASTERCARD = "Mastercard",
  UNKNOWN = "unknown",
  VISA = "Visa",
}

export const getPaymentMethodIcon = (cardScheme: CardScheme) => {
  switch (cardScheme) {
    case CardScheme.AMEX:
      return AmexIcon;
    case CardScheme.MASTERCARD:
      return MastercardIcon;
    case CardScheme.VISA:
      return VisaIcon;
    case CardScheme.UNKNOWN:
    default:
      return CardIcon;
  }
};
