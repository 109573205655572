import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next/initReactI18next";

export const DEFAULT_LANGUAGE = "en";
const SECONDARY_FALLBACK_LANGUAGE = "fr";
export const APPS_SUPPORTED_LANGUAGES = ["fr", "en", "it", "nl", "de", "es"] as const;
export type AppsSupportedLanguage = (typeof APPS_SUPPORTED_LANGUAGES)[number];
const namesSpaces = ["business", "pnf", "partners", "common", "partners", "webviews"];
type TranslationNamespace = (typeof namesSpaces)[number];

const initI18next = (ns: TranslationNamespace[] = namesSpaces, debug: boolean = true) => {
  if (!i18n.isInitialized) {
    // eslint-disable-next-line import/no-named-as-default-member
    i18n
      .use(initReactI18next)
      .use(LanguageDetector)
      .use(HttpApi)
      .init({
        backend: {
          backend: {
            loadPath: "/locales/{{lng}}/{{ns}}.json",
          },
        },
        debug,
        detection: {
          caches: [],
          // Localstorage config for language switch
          //caches: ["localStorage"],
          //lookupLocalStorage: LOCAL_STORAGE_KEY,
          // order: ["localStorage", "navigator"],
          order: ["navigator"],
        },
        // Fallback and default language is english :
        // - if the user's language isn't supported (browser languages, in preference order) we display the english translations
        // - if a translation value is empty, we display the english one
        // - if the english translation is empty, we display the french one
        fallbackLng: [DEFAULT_LANGUAGE, SECONDARY_FALLBACK_LANGUAGE],
        interpolation: {
          escapeValue: false,
        },
        load: "languageOnly",
        nonExplicitSupportedLngs: false,
        ns,
        returnEmptyString: false,
        returnNull: false,
        saveMissing: false,
        supportedLngs: APPS_SUPPORTED_LANGUAGES,
      });
  }

  return i18n;
};

export default initI18next;
